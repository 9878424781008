<template>
  <v-sheet class="offre-wrapper" height="100%">
    <v-container class="" style="padding: 3.5rem 0">
      <h1 class="text-center display-3 font-weight-medium">Types de prestation</h1>
      <v-row style="margin-top: 5rem">
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
            <div class="d-flex flex-column flex-sm-row align-center">
              <v-avatar size="125" tile>
                <v-img src="~@/assets/illustrations/icone04.png"></v-img>
              </v-avatar>
              <v-card-title>Transfert aéroport</v-card-title>
            </div>
            <p class="mt-10">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores error facere sed. Ab deserunt obcaecati omnis nihil, distinctio fugiat, unde officiis officia alias voluptas quia ullam molestias ea fugit id.</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
        <v-card>
            <v-card-text>
            <div class="d-flex flex-column flex-sm-row align-center">
              <v-avatar size="125" tile>
                <v-img src="~@/assets/illustrations/icone05.png"></v-img>
              </v-avatar>
              <v-card-title>Mise à disposition</v-card-title>
            </div>
            <p class="mt-10">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores error facere sed. Ab deserunt obcaecati omnis nihil, distinctio fugiat, unde officiis officia alias voluptas quia ullam molestias ea fugit id.</p>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
    <reservation-bar class="mt-10" />
  </v-sheet>
</template>

<script>
import ReservationBar from "../../components/public/ReservationBar.vue";
export default {
  components: { ReservationBar },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200px";
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "700px";
        case "xl":
          return "800px";
      }
    },
  },
};
</script>

<style>
.w-75 {
  width: 75%;
}

.w-35 {
  width: 35%;
}
</style>
