<template>
  <div class="section-divider section-1">
    <v-card color="primary" class="py-3">
      <v-card-text class="d-flex flex-column flex-sm-row align-center justify-center pa-10">
        <div class="timeline-wrapper">
          <v-timeline align-top dense>
            <v-timeline-item
              :icon="'mdi-home'"
              color="secondary"
              fill-dot
            ></v-timeline-item>
            <v-timeline-item
              :icon="'mdi-map-marker'"
              color="secondary"
              fill-dot
            ></v-timeline-item>
          </v-timeline>
        </div>
        <div class="right mr-8">
          <div class="radio-wrapper">
            <v-radio-group v-model="tripReason" class="secondary--text">
              <v-radio value="Mise à disposition" color="secondary">
                <template v-slot:label>
                  <div class="subtitle-2">Mise à disposition</div>
                </template>
              </v-radio>
              <v-radio value="Transfert vers l'aéroport" color="secondary">
                <template v-slot:label>
                  <div class="subtitle-2">Transfert vers l'aéroport</div>
                </template>
              </v-radio>
              <v-radio value="Transfert depuis l'aéroport" color="secondary">
                <template v-slot:label>
                  <div class="subtitle-2">Transfert depuis l'aéroport</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="call-to-action mt-3">
          <v-btn color="secondary">Réservez votre van</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {

  data() {
    return {
      tripReason: ''
    }
  }
};
</script>

<style></style>
